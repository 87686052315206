import { gql } from '@apollo/client';
import MATCH_FRAGMENT from '../fragments/match';

export default gql`
  query matchesWithVideoFilters(
    $date: LocalDate
    $queryParams: QueryParams
    $assigned: Boolean
    $acquisitionTypes: [AcquisitionType!]
    $plannedAcquisitionInterval: plannedAcquisitionInterval
    $lineId: String
    $matchId: Int
  ) {
    matchesWithVideoFilters(
      date: $date
      queryParams: $queryParams
      assigned: $assigned
      acquisitionTypes: $acquisitionTypes
      plannedAcquisitionInterval: $plannedAcquisitionInterval
      lineId: $lineId
      matchId: $matchId
    ) {
      matches {
        ...MatchFragmment
      }
      totalCount
    }
  }
  ${MATCH_FRAGMENT}
`;
