import React from 'react';
import classNames from 'classnames';
import {
  flatten,
  isEmpty,
  isNil,
  mapObjIndexed,
  pipe,
  values,
  head,
  path,
  defaultTo,
} from 'ramda';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { Divider, Label, Text } from 'components';
import {
  acronym,
  formatEtaLocal,
  formatKO,
  textToBase64Img,
  title,
} from 'utils/helpers';
import { Checkbox, Classes, Position, Spinner } from '@blueprintjs/core';
import { Acl, RenderIf } from 'config';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import { Tooltip2 } from '@blueprintjs/popover2';
import { matchesActions } from 'state/matchesState';

const VideoMatchAssignmentCard = ({
  match = {},
  className,
  type,
  index,
  matchDate,
  squadName,
  matchesDispatch,
  matchesState,
}) => {
  const {
    id,
    priority = {},
    kickOff,
    area,
    competitionSeason: { competitionName = '', seasonName = '' } = {},
    squadShifts = [],
    homeTeam: { name: homeTeam } = {},
    awayTeam: { name: awayTeam } = {},
    plannedAcquisitionDate,
    acquisitionType,
    videoETA,
  } = match;

  const isMatchSelected = matchesState.selectedMatches?.[matchDate]?.[id];
  const selectedMatchesList = pipe(
    mapObjIndexed(values),
    values,
    flatten
  )(matchesState?.selectedMatches);

  const handleSelectionChange = (e) => {
    const isSelected = e.target.checked;
    matchesDispatch({
      type: isSelected
        ? matchesActions.SELECT_MATCH
        : matchesActions.DE_SELECT_MATCH,
      payload: {
        date: matchDate,
        matchId: id,
        kickOff,
      },
    });
  };
  const { value = '', slaOffset = '' } = priority || {};
  const lineSquadShift = path(
    ['squadShift', 'id'],
    head(defaultTo([], squadShifts))
  );
  const isAssigned = !isNil(lineSquadShift);
  const [{ opacity }, drag, preview] = useDrag({
    type,
    item: { id: selectedMatchesList },
    isDragging(monitor) {
      const item = monitor.getItem();
      const idsList = item.id.map((i) => i.matchId);
      return idsList.includes(id);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        // remove match dropped from matches list
        // query refetching is taking care of that but manual optimistic response can be added here so card can fade out and manual update cache can be used instead of refetch
      }
    },
  });

  const dragPreviewImgSrc = textToBase64Img(
    `Moving ${selectedMatchesList.length} matches`
  );

  const localTimeKO = formatKO(matchDate, kickOff);
  const videoEtaFormatted = videoETA ? formatEtaLocal(videoETA) : 'N/A';
  const plannedAcquisitionDateFormatted = plannedAcquisitionDate
    ? formatEtaLocal(plannedAcquisitionDate)
    : 'N/A';

  const renderCardContent = () => (
    <Grid className="p-0 mg-0">
      <Row className="match-card-ops-info" middle="lg">
        <Col className="p-5">
          <RenderIf condition={!isAssigned}>
            <Checkbox
              onChange={handleSelectionChange}
              large
              checked={isMatchSelected}
              className="with-check-mark-checkbox"
            />
          </RenderIf>
        </Col>
        <Col lg={!isNil(priority) ? 2 : 0}>
          <RenderIf condition={!isNil(priority)}>
            <Label
              variant="ColoredLabel"
              labelText={`${acronym(value)}:${slaOffset}`}
              index={index}
              screenType="teams"
              className="customize_colored_label"
            />
          </RenderIf>
        </Col>
        <Col lg={2}>
          <Label variant="Copyable" labelHeader="ID:" labelText={id} inline />
        </Col>
        <Col lg={4} md={3}>
          <Text variant="body2">{`${competitionName} ${seasonName}`}</Text>
        </Col>
        <Col>
          <Tooltip2
            content={`${area.name}`}
            position={Position.TOP_LEFT}
            className={Classes.TOOLTIP_INDICATOR}
            minimal
            usePortal
          >
            <span
              className={classNames([
                'fflag',
                'ff-lg ',
                'ff-round',
                `fflag-${area?.code}`,
              ])}
            />
          </Tooltip2>
        </Col>

        <Col lg={2} md={2}>
          <RenderIf
            condition={isAssigned}
            renderElse={
              <Text variant="body1" className="unassigned-label">
                <FormattedMessage id="containers.all.unassigned" />
              </Text>
            }
          >
            <RenderIf condition={squadName} renderElse={<Spinner size={5} />}>
              <div className="flex justify-end squad-custom-label-text">
                <Text variant="body3" className="color-qs">
                  Squad:
                </Text>
                &nbsp;
                <Text variant="body3">{squadName}</Text>
              </div>
            </RenderIf>
          </RenderIf>
        </Col>
      </Row>
      <Divider />
      <Row className="match-card-match-info" between="lg" middle="lg">
        <Col lg={4} md={10}>
          <Text variant="body1" className="styled-team-name">
            {homeTeam}
          </Text>
          <Text className="vs-styled">&nbsp;vs.&nbsp;</Text>
          <Text variant="body1" className="styled-team-name">
            {awayTeam}
          </Text>
        </Col>
      </Row>
      <Row className="match-card-match-info">
        <Col lg={6} className="ko-acq-type" md={6}>
          <div className="preview-mode">
            <Label
              variant="Baisc"
              inline
              labelHeader="KO: &nbsp;"
              labelText={localTimeKO}
            />
            <Label
              variant="Baisc"
              inline
              labelHeader="Acq. Type: &nbsp;"
              labelText={title(acquisitionType)}
            />
          </div>
        </Col>
        <Col lg={6} className="ko-acq-type" md={6}>
          <div className="preview-mode">
            <Label
              variant="Baisc"
              inline
              labelHeader="Video ETA: &nbsp;"
              labelText={videoEtaFormatted}
            />
            <Label
              variant="Baisc"
              inline
              labelHeader="Planned Acq. Time: &nbsp;"
              labelText={plannedAcquisitionDateFormatted}
            />
          </div>
        </Col>
      </Row>
    </Grid>
  );

  const NonDraggableCard = () => (
    <div
      className={classNames('match-card-container', 'no-access', className, {
        unassigned: !isAssigned,
        'disabled-match': isNil(priority),
      })}
    >
      {renderCardContent()}
    </div>
  );
  if (isEmpty(match))
    return <FormattedMessage id="containers.all.emptyMatches" />;
  return (
    <Acl
      allowedPermissions={[
        'create:match-assignment',
        'update:match-assignment',
      ]}
      allowedRoleNames={['super-admin']}
      renderNoAccess={<NonDraggableCard />}
    >
      <>
        <DragPreviewImage connect={preview} src={dragPreviewImgSrc} />
        <div
          className={classNames('match-card-container', className, {
            unassigned: !isAssigned,
            'disabled-match': isNil(priority),
          })}
          ref={!isNil(priority) && isMatchSelected ? drag : null}
          style={{ opacity }}
          id={`dragPreviewDiv${id}`}
        >
          {renderCardContent()}
        </div>
      </>
    </Acl>
  );
};

export default VideoMatchAssignmentCard;
