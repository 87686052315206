import { gql } from '@apollo/client';
import { MATCH_FRAGMENT } from 'gql';

export default gql`
  query AcquisitionVideoMatches(
    $dates: [LocalDate]
    $queryParams: QueryParams
    $matchId: Int
    $priorityIds: [String!]
    $acquisitionTypes: [AcquisitionType!]
    $providerTypes: [String!]
    $vod: Boolean
  ) {
    acquisitionVideoMatches(
      dates: $dates
      queryParams: $queryParams
      matchId: $matchId
      priorityIds: $priorityIds
      acquisitionTypes: $acquisitionTypes
      providerTypes: $providerTypes
      vod: $vod
    ) {
      matches {
        ...MatchFragmment
      }
      totalCount
    }
  }
  ${MATCH_FRAGMENT}
`;
