export const JOB_TYPES = ['FULL_TIME', 'PART_TIME'];

export const POWERS = [
  0, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0,
];

export const ACCOUNT_STATUSES = ['ACTIVE', 'DEACTIVATED'];

export const PRIORITY_TEAM_TYPE = ['MEN', 'WOMEN'];
export const PRIORITY_IS_FEMALE = [
  {
    text: 'Men',
    value: false,
  },
  {
    text: 'Women',
    value: true,
  },
];

export const SLAS = [8, 12, 24, 36, 48, 60, 72, 84, 96];

export const SHIFT_TYPES = ['MORNING', 'NIGHT', 'OVER_NIGHT', 'OVER_NIGHT_2'];

export const MATCH_STATUS = ['Assigned', 'Unassigned'];
export const TEAM_SIDE = ['HOME', 'AWAY'];

export const MAP_HALVES = {
  FIRST: '1st',
  SECOND: '2nd',
  THIRD: '3rd',
  FOURTH: '4th',
  FIFTH: '5th',
};

export const PRESENT_TYPES = [
  'PRESENT',
  'PRESENT_TA',
  'PRESENT_ES',
  'PRESENT_DS',
];

export const REPORT_ISSUES = [
  'TECHNICAL_ISSUE',
  'INFO_ISSUE',
  'POSTPONED',
  'SUSPENDED',
  'VIDEO_ISSUE',
  'MISSING_PRIORITY',
  'QUALITY_ISSUE',
  'OTHER',
];
export const ISSUES_TYPES = [
  'TECHNICAL_ISSUE',
  'MISSING_PLAYER',
  'METADATA_ISSUE',
  'POSTPONED',
  'SUSPENDED',
  'VIDEO_ISSUE',
  'MISSING_PRIORITY',
  'BAD_CAMERA',
  'BAD_SHIRT',
  'BAD_VIDEO',
  'OTHER',
];

export const VIDEO_ISSUES = [
  'NOT_BROADCASTED',
  'ACCESS_ISSUE',
  'CONNECTION_ISSUE',
  'CANCELLED',
  'WRONG_KO',
  'RESCHEDULED',
  'DEPLOYMENT_ERROR',
  'REPLAY_NOT_AVAILABLE',
  'MISSING_MINUTES',
  'BAD_CAMERA_ANGLE',
  'LOW_QUALITY',
  'OTHER',
];
export const QUALITY_ISSUE = [
  { text: 'Bad Shirt', value: 'BAD_SHIRT' },
  { text: 'Bad Camera', value: 'BAD_CAMERA' },
  { text: 'Bad Video', value: 'BAD_VIDEO' },
];

export const BAD_VIDEO = [
  { text: 'Whole Match Video', value: true },
  { text: 'Timestamp', value: false },
];

export const QUALITY_ISSUE_LIST = ['BAD_CAMERA', 'BAD_SHIRT', 'BAD_VIDEO'];
export const INFO_ISSUE_LIST = ['MISSING_PLAYER', 'METADATA_ISSUE'];
export const STATUS = ['BLOCKED', 'REPORTED', 'NORMAL'];
export const COLLECTION_STATUS = ['NOT_STARTED', 'COMPLETED', 'IN_PROGRESS'];
export const MATCH_STATUS_FILTER = [...COLLECTION_STATUS, ...STATUS];
export const HALF = [
  {
    text: 'First Half',
    value: 'FIRST',
  },
  {
    text: 'Second Half',
    value: 'SECOND',
  },
  {
    text: 'Third Half',
    value: 'THIRD',
  },
  {
    text: 'Fourth Half',
    value: 'FOURTH',
  },
];

export const PART = ['FIRST', 'SECOND'];

export const HALVES = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
  FIFTH: '5',
};

export const PARTS = {
  FIRST: 'P1',
  SECOND: 'P2',
};

export const DEACTIVATION_REASONS = [
  {
    text: 'General Personal Leave',
    value: 'GENERAL_PERSONAL',
  },
  {
    text: 'Marriage Leave',
    value: 'MARRIAGE',
  },
  {
    text: 'Long Sick Leave',
    value: 'LONG_SICK',
  },
  {
    text: 'Military Service',
    value: 'MILITARY',
  },
];

export const SPORTS = {
  amf: 'AMF',
  football: 'Football',
  pregame: 'PreGame',
  other: 'Other',
};

export const TEAM = 'TEAM';
export const COMPETITION_SEASON = 'COMPETITION_SEASON';
export const SPORTS_LIST = ['AMF', 'FOOTBALL', 'PREGAME', 'OTHER'];

export const providerTypeIconMapper = {
  STREAMING: 'feed',
  CHANNEL: 'satellite',
  IPTV: 'mobile-video',
  CUSTOMER_DRIVE: 'floppy-disk',
  FEED_EXTENSION: 'feed-subscribed',

  competition: '',
};

export const streamingDataIconMapper = {
  vpn: 'shield',
  downloadable: 'download',
  vod: 'video',
};
export const VOD_STATUS = ['VOD', 'No VOD'];

export const USER_LINE_NAMES = {
  VIDEO: 'VIDEO',
  OFFLINE: 'OFFLINE',
};

export const VIDEO_SHIFT_INTERVALS = [
  {
    label: '6:30 AM - 2:30 PM (Morning)',
    value: { startTime: '06:30:00', endTime: '14:30:00', shift: 'MORNING' },
  },
  {
    label: '2:30 PM - 10:30 PM (Night)',
    value: { startTime: '14:30:00', endTime: '22:30:00', shift: 'NIGHT' },
  },
  {
    label: '10:30 PM - 6:30 AM (Overnight)',
    value: { startTime: '22:30:00', endTime: '06:30:00', shift: 'OVERNIGHT' },
  },
  {
    label: 'Later',
    value: { startTime: '00:00:00', endTime: undefined, later: true },
  },
];

export const FEATURE_TYPES = {
  SATELLITE: 'SATELLITE',
  SOFTWARE: 'SOFTWARE',
  HARDWARE: 'HARDWARE',
  IPTV_DEVICE: 'IPTV_DEVICE',
};
export const PROVIDER_TYPES = {
  IPTV: 'IPTV',
  STREAMING: 'STREAMING',
  CHANNEL: 'CHANNEL',
  CUSTOMER_DRIVE: 'CUSTOMER_DRIVE',
  FEED_EXTENSION: 'FEED_EXTENSION',
};

// TODO: this may get form BE

export const VIDEO_STATUS_OPTIONS = [
  {
    label: 'Planned',
    value: 'PLANNED',
    icon: 'video-status-planned',
  },
  {
    label: 'Assigned',
    value: 'ASSIGNED',
    icon: 'video-status-assigned',
  },
  {
    label: 'Recorded',
    value: 'RECORDED',
    icon: 'video-status-recorded',
  },
  {
    label: 'Uploaded',
    value: 'UPLOADED',
    icon: 'video-status-uploaded',
  },
  {
    label: 'Recovered and Completed',
    value: 'RECOVERED',
    icon: 'video-status-recovered',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
    icon: 'video-status-completed',
  },
];
