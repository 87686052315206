import { gql } from '@apollo/client';
import SQUAD_CATEGORY_FRAGMENT from '../fragments/squadCategory';

export default gql`
  query squadCategories($queryParams: QueryParams) {
    squadCategories(queryParams: $queryParams) {
      totalCount
      squadCategories {
        ...SquadCategoryFragment
      }
    }
  }
  ${SQUAD_CATEGORY_FRAGMENT}
`;
