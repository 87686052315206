import { gql } from '@apollo/client';

export default gql`
  mutation updateSquadCategory($input: UpdateSquadCategoryInput!) {
    updateSquadCategory(input: $input) {
      name
      id
      isQA
      updatedAt
    }
  }
`;
