import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { SideNav, Header } from 'components';
import { Spinner } from '@blueprintjs/core';
import { useProfile } from 'providers/profileProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { Acl, RenderIf } from 'config';
import { defaultTo, path, equals } from 'ramda';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ReactHeap from 'reactjs-heap';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'providers/toastProvider';

const MainLayout = ({ children }) => {
  const HEAP_ID = process.env.REACT_APP_HEAP_ID;
  const PHONE_BREAK_POINT = 599;

  const { loginWithRedirect } = useAuth0();
  const [sideCollapsed, setSideCollapsed] = useState(true);
  const { navigate } = useNavigation();
  const profile = useProfile();
  const {
    authLoading,
    authError,
    isAuthenticated,
    profileLoading,
    profile: userProfile,
  } = profile;

  const allowedRolePermissions = defaultTo(
    [],
    path(['data', 'allowedRolePermissions'], useCurrentRoute())
  );
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const renderNoAccess = <FormattedMessage id="containers.all.limit.access" />;
  const loading = profileLoading || authLoading;

  useLayoutEffect(() => {
    ReactHeap.initialize(HEAP_ID);
    if (window.innerWidth <= PHONE_BREAK_POINT) {
      showToast({
        icon: 'none',
        timeout: 6000,
        message: formatMessage({ id: 'layout.rotatePhone' }),
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= PHONE_BREAK_POINT && !sideCollapsed) {
      setSideCollapsed(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (equals(userProfile?.line?.sport, 'AMF')) {
      navigate('/user-management');
    }
  }, [userProfile]);

  return (
    <Grid fluid className="p-0 mg-0">
      <Row className="mg-0">
        <Col sm={12} className="p-0">
          <Header
            loginWithRedirect={loginWithRedirect}
            user={userProfile}
            isAuthenticated={isAuthenticated}
            authLoading={authLoading}
            sideCollapsed={sideCollapsed}
            setSideCollapsed={setSideCollapsed}
          />
        </Col>
      </Row>
      <RenderIf condition={loading}>
        <Spinner />
      </RenderIf>
      <RenderIf condition={isAuthenticated && !loading && !authError}>
        <div className="main-layout flex">
          <SideNav
            profile={profile}
            collapsed={sideCollapsed}
            setCollapsed={setSideCollapsed}
          />
          <Acl
            allowedPermissions={allowedRolePermissions}
            renderNoAccess={renderNoAccess}
          >
            <div
              className={classNames('main-content', {
                'side-expanded': !sideCollapsed,
              })}
            >
              {children}
            </div>
          </Acl>
        </div>
      </RenderIf>
    </Grid>
  );
};

export default MainLayout;
