import React from 'react';
import ReactDOM from 'react-dom';
import { Router, View } from 'react-navi';
import MainLayout from 'layouts/MainLayout';
import ModalProvider from 'providers/modalProvider';
import ToastProvider from 'providers/toastProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloAuthProvider from 'providers/apolloAuthProvider';
import ProfileProvider from 'providers/profileProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { routes, ErrorBoundary } from 'config';
import { I18Provider, LOCALES } from './i18n';
import 'scss/main.scss';

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_API_IDENTIFIER}
      connection={process.env.REACT_APP_AUTH0_DB_CONNECTION}
      cacheLocation="localstorage"
    >
      <ApolloAuthProvider>
        <ProfileProvider>
          <Router routes={routes}>
            <I18Provider locale={LOCALES.ENGLISH}>
              <ToastProvider>
                <DndProvider backend={HTML5Backend}>
                  <ModalProvider>
                    <MainLayout>
                      <View />
                    </MainLayout>
                  </ModalProvider>
                </DndProvider>
              </ToastProvider>
            </I18Provider>
          </Router>
        </ProfileProvider>
      </ApolloAuthProvider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
