import { gql } from '@apollo/client';
import SQUAD_CATEGORY_FRAGMENT from '../fragments/squadCategory';

export default gql`
  query squadCategory($squadCategoryId: String!) {
    squadCategory(id: $squadCategoryId) {
      ...SquadCategoryFragment
    }
  }
  ${SQUAD_CATEGORY_FRAGMENT}
`;
