import React, { useMemo } from 'react';
import { Label, Button, Icon } from 'components';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useModal } from 'providers/modalProvider';
import { RenderIf } from 'config';
import { isEmpty } from 'ramda';
import { title } from 'utils/helpers';
import LogoIcon from 'assets/logo.png';
import PropTypes from 'prop-types';

const Header = ({
  user = {},
  loginWithRedirect,
  isAuthenticated,
  authLoading,
  sideCollapsed,
  setSideCollapsed,
}) => {
  const { modalDispatch } = useModal();
  const { firstName = '', lastName = '' } = user;
  const quickActions = useMemo(
    () => (
      <Menu className="header-sign-out-menu">
        <MenuItem
          text="Change Password"
          icon={<Icon customIcon="password" />}
          onClick={() =>
            modalDispatch({
              type: 'CHANGE_PASSWORD',
            })
          }
        />
        <MenuItem
          text="Sign Out"
          icon={<Icon customIcon="out" />}
          onClick={() =>
            modalDispatch({
              type: 'SIGN_OUT',
            })
          }
        />
      </Menu>
    ),
    []
  );
  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={LogoIcon} alt="Logo" />
      </div>
      <div className="flex items-center">
        <RenderIf
          condition={isAuthenticated}
          renderElse={
            <Button
              onClick={loginWithRedirect}
              intent="Primary"
              loading={authLoading}
            >
              Sign In
            </Button>
          }
        >
          <RenderIf condition={!isEmpty(user)}>
            <Popover2 content={quickActions} position="bottom">
              <Label
                variant="AvatarLabel"
                labelText={`${title(firstName)} ${title(lastName)}`}
                avatarText={`${firstName} ${lastName}`}
                className="automarginLeft"
                size={20}
              />
            </Popover2>
          </RenderIf>
          <Button
            minimal
            icon="menu"
            className="header-expand-side"
            onClick={() => setSideCollapsed(!sideCollapsed)}
          />
        </RenderIf>
      </div>
    </div>
  );
};

export default Header;
Header.propTypes = {
  user: PropTypes.shape({}).isRequired,
  loginWithRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authLoading: PropTypes.bool.isRequired,
  sideCollapsed: PropTypes.bool.isRequired,
  setSideCollapsed: PropTypes.func.isRequired,
};
