import { gql } from '@apollo/client';
import MATCH_FRAGMENT from '../fragments/match';

export default gql`
  query VideoMatches(
    $dates: [LocalDate]
    $queryParams: QueryParams
    $matchId: Int
    $priorityIds: [String!]
    $providerTypes: [String!]
    $vod: Boolean
  ) {
    videoMatches(
      dates: $dates
      queryParams: $queryParams
      matchId: $matchId
      priorityIds: $priorityIds
      providerTypes: $providerTypes
      vod: $vod
    ) {
      ...MatchFragmment
    }
  }
  ${MATCH_FRAGMENT}
`;
