import { defaultTo, difference, filter, prop } from 'ramda';
import { getSquadShiftId } from 'utils/helpers';

// eslint-disable-next-line import/prefer-default-export
export const arrangeMatches = (matches = []) => {
  const assignedMatches = filter(getSquadShiftId, matches || []);
  const unassignedMatches =
    difference(defaultTo([], matches), assignedMatches) || [];
  const unassignedWithPriority =
    filter(prop('priority'), unassignedMatches) || [];
  const others = difference(unassignedMatches, unassignedWithPriority) || [];
  const sortedMatches = [
    ...unassignedWithPriority,
    ...assignedMatches,
    ...others,
  ];
  return sortedMatches;
};
