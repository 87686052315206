import { omit } from 'ramda';
import { DateObject } from 'react-multi-date-picker';
import { isMatchHasSquadShift } from 'utils/helpers';

const today = new DateObject().format('YYYY-MM-DD');
export const matchesInitialState = {
  activeDate: today,
  searchValue: undefined,
  openFilters: false,
  filtersData: {},
  datesList: [],
  selectedMatches: {},
};

export const matchesActions = {
  SET_DATE: 'SET_DATE',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  SET_OPEN_FILTERS: 'SET_OPEN_FILTERS',
  SET_ACTIVE_FILTERS_DATA: 'SET_ACTIVE_FILTERS_DATA',
  SELECT_MATCH: 'SELECT_MATCH',
  DE_SELECT_MATCH: 'DE_SELECT_MATCH',
  SELECT_DAY: 'SELECT_DAY',
  DE_SELECT_DAY: 'DE_SELECT_DAY',
  CLEAR_SELECTED_MATCHES: 'CLEAR_SELECTED_MATCHES',
};

export const matchesReducer = (state, action) => {
  const { type, payload } = action;
  if (type === matchesActions.SET_SEARCH_VALUE) {
    return { ...state, filtersData: {}, ...payload };
  }

  if (type === matchesActions.SET_ACTIVE_FILTERS_DATA) {
    return { ...state, searchValue: undefined, ...payload };
  }

  if (type === matchesActions.SET_OPEN_FILTERS) {
    return { ...state, ...payload };
  }

  if (type === matchesActions.SET_DATE) {
    return { ...state, ...payload };
  }

  if (type === matchesActions.SELECT_MATCH) {
    const { matchId, kickOff, date } = payload;
    return {
      ...state,
      selectedMatches: {
        ...state.selectedMatches,
        [date]: {
          ...state.selectedMatches[date],
          [matchId]: {
            kickOffDateTime: `${date}T${kickOff}Z`,
            matchId,
          },
        },
      },
    };
  }

  if (type === matchesActions.DE_SELECT_MATCH) {
    const { matchId, date } = payload;
    const updatedDateSelectedMatches = omit(
      [matchId],
      state.selectedMatches[date]
    );
    return {
      ...state,
      selectedMatches: {
        ...state.selectedMatches,
        [date]: updatedDateSelectedMatches,
      },
    };
  }

  if (type === matchesActions.SELECT_DAY) {
    const { date, matches } = payload;

    const dateMatches = matches.reduce((prev, current) => {
      if (isMatchHasSquadShift(current)) return prev;
      return {
        ...prev,
        [current.id]: {
          matchId: current.id,
          kickOffDateTime: `${current.date}T${current.kickOff}Z`,
        },
      };
    }, {});
    return {
      ...state,
      selectedMatches: {
        ...state.selectedMatches,
        [date]: {
          ...state.selectedMatches[date],
          ...dateMatches,
        },
      },
    };
  }

  if (type === matchesActions.DE_SELECT_DAY) {
    const { date, matches } = payload;
    const deselectedIds = matches.map((m) => m.id);
    const updatedDateSelectedMatches = omit(
      deselectedIds,
      state.selectedMatches[date]
    );
    return {
      ...state,
      selectedMatches: {
        ...state.selectedMatches,
        [date]: updatedDateSelectedMatches,
      },
    };
  }

  if (type === matchesActions.CLEAR_SELECTED_MATCHES) {
    return {
      ...state,
      selectedMatches: {},
    };
  }
  return state;
};
