import React from 'react';
import { Dropdown } from 'components';
import { useIntl } from 'react-intl';
import { isNil } from 'ramda';
import { VIDEO_SHIFT_INTERVALS } from 'utils/enums';

const AcqIntervalFilter = ({ value, onChange, loading }) => {
  const { formatMessage } = useIntl();
  const buttonText =
    value?.label ??
    formatMessage({
      id: 'containers.acquisitionTypes.form.interval',
    });
  return (
    <Dropdown
      items={VIDEO_SHIFT_INTERVALS}
      value={value}
      onChange={onChange}
      loading={loading}
      buttonText={buttonText}
      disabled={isNil(VIDEO_SHIFT_INTERVALS)}
      disableDropdownButton={isNil(VIDEO_SHIFT_INTERVALS)}
      clearable
      data-testid="acq-interval-filter"
      name="acq-interval"
    />
  );
};

export default AcqIntervalFilter;
