import React, { useContext, createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { map, defaultTo, path } from 'ramda';
import { FullScreenErrorMsg } from 'components';
import { PROFILE_QUERY } from '../gql';

export const ProfileContext = createContext(null);
export const useProfile = () => useContext(ProfileContext);

const ProfileProvider = ({ children }) => {
  const {
    isLoading: authLoading,
    error: authError,
    isAuthenticated,
    user: authUser,
  } = useAuth0();

  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
    refetch: refetchProfile,
  } = useQuery(PROFILE_QUERY, {
    skip: !isAuthenticated || authLoading || authError,
  });

  const profile = profileData?.profile;
  const roleName = profile?.jobTitleRole?.role?.name;
  const rolePermissions = map(
    path(['permission', 'scope']),
    defaultTo([], profile?.jobTitleRole?.role?.permissions)
  );
  const userPermissions = map(
    path(['permission', 'scope']),
    defaultTo([], profile?.permissions)
  );
  // eslint-disable-next-line no-console
  if (authError) console.log({ authError });
  if (profileError) return <FullScreenErrorMsg action={refetchProfile} />;
  return (
    <ProfileContext.Provider
      value={{
        isAuthenticated,
        authLoading,
        authError,
        authUser,
        profile,
        refetchProfile,
        profileLoading,
        profileError,
        roleName,
        rolePermissions,
        userPermissions,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
