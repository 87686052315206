import { gql } from '@apollo/client';

export default gql`
  fragment SquadCategoryFragment on SquadCategory {
    id
    name
    isQA
    _count {
      squads
    }
  }
`;
